export class Colors {
  primary: string = '#0072CE';
  primary_dark: string = '#0072CE';
  secondary: string = '#0F1923';
  danger: string = '#c8102e';
  warning: string = '#ffc409';
  light: string = '#f4f5f8';
  gray: string = '#989aa2';
  dark: string = '#0F1923';
  black: string = '#000000';
  white: string = '#ffffff';
  primary_contrast: string = '#ffffff';
  toolbar: string = '#f8f8f8';
}
export class AppConfig {
  colors: Colors = new Colors();
  title: string = 'Rexolution Charge';
  appID: string = '';
  appHost: string = 'charge.rexel.se';
  showTitle: boolean = false;
  defaultLang: string = 'sv';
  appToken: string = '946feea6-1c53-45ff-81cf-ed770f2d3307'; //Whitelabel token
  pushAppID: string = '2c8b6288-9b1c-498b-83e9-bcfc29b1fd57'; //Onesignal app ID
  pushSenderID: string = '705633388762'; //Firebase sender ID
  root: string = 'https://scpay.smartcharge.io';
  readonly isWhitelabel: boolean = this.appToken ? true : false;
  stationIcon: StationIcon = { height: 44, width: 30 };
  availableLangs: string[] = ['nb', 'en', 'da', 'sv'];
  toolbarLogoHeight: string = '1.6em';
  privacyPolicy: any = {
    sv: 'https://energy.rexel.se/integritetspolicy/',
    en: 'https://energy.rexel.se/integritetspolicy/'
  };
  termsAndConditions: any = {
    sv: 'https://energy.rexel.se/anvandarvillkor/',
    en: 'https://energy.rexel.se/anvandarvillkor/'
  };
  contact: any = {
    external: false,
    email: 'charge@rexel.se',
    phone: '+46 8 - 121 319 00',
    web: 'https://energy.rexel.se',
    chat: '',
    customerService: {}
  };
  constructor() {}
}

interface StationIcon {
  height: number;
  width: number;
}
